<template>
  <page-layout
    :title="$t('help.title')"
    :has-back-button="true"
  >
    <div id="helpPage">
      <el-alert
        type="info"
        :closable="false"
        class="view-admin__alert"
        show-icon
      >
        <span v-html="$t('help.info_bar')" />
      </el-alert>

      <div class="section" :key="`${index}-${section.name}`" v-for="(section,index) in getFilteredLinks">
        <template v-if="section.links.length">
          <div class="list-group-name title">{{section.name}}</div>
          <div class="list-group">
            <el-row
              type="flex"
              justify="space-between"
              align="center"
              :key="`${index}-${link.name}`"
              v-for="(link, index) in section.links"
              class="list-group-item"
              @click.native="openFile(link.url)"
            >
              <el-col type="flex" justify="space-between">
                <el-row type="flex" class="title">
                  <span class="flex-span">{{link.name}}</span>
                  <i class="ri-file-list-2-line"></i>
                </el-row>
                <div>{{getLanguageLabel(link.language)}}</div>
              </el-col>
              <i class="ri-arrow-right-s-line"></i>
            </el-row>
          </div>
        </template>
      </div>
    </div>
  </page-layout>
</template>

<script>
import { mapGetters } from 'vuex'
import PageLayout from '../layouts/PageLayout'

export default {
  name: 'Help',
  data () {
    return {
      languages: [
        {
          value: 'nl',
          label: 'Nederlands'
        },
        {
          value: 'fr',
          label: 'Français'
        },
        {
          value: 'en',
          label: 'English'
        }
      ]
    }
  },
  components: {
    PageLayout
  },
  computed: {
    ...mapGetters({
      help: 'app/help',
      language: 'i18n/langDefault'
    }),
    getFilteredLinks () {
      return this.help.length
        ? this.help.map(section => {
          return {
            ...section,
            links: section.links.filter(link => link.language === this.language)
          }
        })
        : []
    }
  },
  methods: {
    openFile (url) {
      window.open(url, '_blank ')
    },
    getLanguageLabel (value) {
      const language = this.languages.find(language => language.value === value)
      return language ? language.label : ''
    }
  }
}
</script>

<style scoped>

</style>
